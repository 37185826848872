<template>
  <div class="relative inline-block text-left" v-click-away="closeShowDropdown">
    <div>
      <button type="button" class="rounded-full p-2 bg-white hover:bg-gray-200 text-sm font-medium text-gray-700 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-offset-gray-100 focus:ring-gray-200" id="options-menu" aria-haspopup="true" aria-expanded="true" @click.stop="openShowDropdown">
        <Icon icon="tabler:dots-vertical" class="h-4 w-4" />
      </button>
    </div>
    <TransitionFade2>
      <div class="origin-top-right absolute z-100 right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5" v-if="showDropdown">
        <div class="py-2 px-2" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
          <slot name="buttons"></slot>
        </div>
      </div>
    </TransitionFade2>
  </div>
</template>

<script>
import { Icon } from "@iconify/vue"
import TransitionFade2 from "@/components/TransitionFade2"

export default {
  name: `Dropdown`,
  components: {
    Icon,
    TransitionFade2
  },
  data: function () {
    return {
      showDropdown: false
    }
  },
  methods: {
    openShowDropdown: function () {
      this.showDropdown = !this.showDropdown
    },
    closeShowDropdown: function () {
      this.showDropdown = false
    }
  }
}
</script>
