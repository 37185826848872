<template>
  <div>
    <div class="border-t border-b border-gray-200 bg-white">
      <div class="container-xl px-4 md:px-2 py-4 h-20 md:h-20 mx-auto flex flex-row items-center justify-between">
        <div class="flex flex-row items-center justify-between md:justify-start">
          <h4 class="text-xl font-semibold leading-tight text-gray-800 flex items-center mr-0 md:mr-5">
            <span>{{ $t("navbar.design") }}</span>
          </h4>
        </div>
        <div class="flex md:flex-row items-center">
          <div class="my-3 flex flex-row space-x-2"></div>
        </div>
      </div>
    </div>

    <div>
      <div class="container-xl py-6">
        <TabView>
          <!-- Templates -->
          <TabPanel>
            <template #header>
              <div class="flex-center-center space-x-2">
                <span> {{ $t("design.templates") }}</span>
                <Badge :value="(templates.meta?.total || 0).toString()" class="bg-gray-200 text-gray-500"></Badge>
              </div>
            </template>
            <div v-if="templateClient.busy">
              <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
                <div v-for="template in 8" :key="template.id">
                  <Card class="relative p-card-content-xs">
                    <template #content>
                      <div class="flex relative" :class="[template.deleting ? 'opacity-30 transition-all' : '']">
                        <div class="w-1/4">
                          <Skeleton width="5rem" height="5rem" />
                        </div>
                        <div class="w-3/4 flex flex-col items-start space-y-1">
                          <Skeleton width="100%" height="2rem" />
                          <Skeleton width="100%" height="2rem" />
                          <Skeleton width="100%" height="1rem" />
                        </div>
                      </div>
                    </template>
                  </Card>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
                <Card v-if="$verifyPermission(permissions, ['v2.client.template.storeOrUpdate'])" class="relative p-card-content-xs">
                  <template #content>
                    <div class="flex space-x-3 relative">
                      <div class="w-1/4">
                        <Icon icon="gg:if-design" class="w-[90px] h-[100px] border rounded-md text-gray-400" />
                      </div>
                      <div class="w-3/4 flex flex-col justify-center">
                        <div class="space-y-2">
                          <div class="text-base text-gray-700 font-semibold uppercase">{{ $t("design.blankTemplate") }}</div>
                          <div class="text-xs text-gray-500 font-semibold">{{ $t("design.StartFromScratch") }}</div>
                          <Button @click="$router.push({ name: 'Template Builder', params: { id: $route.params.id } })" type="button"  :label="$t('design.Startdesigning')" icon="pi pi-file-edit" iconPos="left" class="p-button-primary p-button-sm shadow" />
                        </div>
                      </div>
                    </div>
                  </template>
                </Card>

                <div v-for="template of templates.data" :key="template.id">
                  <Card class="relative p-card-content-xs">
                    <template #content>
                      <div class="absolute z-100 top-2.5 right-2">
                        <CustomDropdown>
                          <template v-slot:buttons>
                            <a href="javascript:void(0)" @click.stop="openSyncModal(template)" class="cursor-pointer flex gap-2 items-center w-full px-2 py-2 text-sm transition duration-150 rounded-md text-gray-600 hover:bg-gray-100 hover:text-gray-800 group" role="menuitem">{{ $t("design.export") }}</a>
                            <a v-if="$verifyPermission(permissions, ['v2.client.template.storeOrUpdate'])" href="javascript:void(0)" @click.stop="$router.push({ name: 'Edit Template', params: { id: $route.params.id, templateId: template.id } })" class="cursor-pointer flex gap-2 items-center w-full px-2 py-2 text-sm transition duration-150 rounded-md text-gray-600 hover:bg-gray-100 hover:text-gray-800 group" role="menuitem">{{ $t("edit") }}</a>
                            <a v-if="$verifyPermission(permissions, ['v2.client.template.duplicate'])" href="javascript:void(0)" @click.stop="duplicateTemplate(template)" class="cursor-pointer flex gap-2 items-center w-full px-2 py-2 text-sm transition duration-150 rounded-md text-gray-600 hover:bg-gray-100 hover:text-gray-800 group" role="menuitem">{{ $t("design.duplicate") }}</a>
                            <a v-if="$verifyPermission(permissions, ['v2.client.template.destroy'])" href="javascript:void(0)" @click.stop="deleteTemplate(template)" class="cursor-pointer flex gap-2 items-center w-full px-2 py-2 text-sm transition duration-150 rounded-md text-gray-600 hover:bg-gray-100 hover:text-gray-800 group" role="menuitem">{{ $t("design.delete") }}</a>
                          </template>
                        </CustomDropdown>
                      </div>
                      <div class="flex space-x-3 relative" :class="[template.deleting ? 'opacity-30 transition-all' : '']">
                        <div class="w-1/4">
                          <img :src="template.thumbnail + '?r=' + Date.now()" class="w-[90px] h-[100px] border rounded-md" />
                        </div>
                        <div class="w-3/4 flex flex-col items-start space-y-1">
                          <div class="template text-sm text-gray-700 font-semibold line-clamp-2" :title="template.name">{{ template.name }}</div>
                          <div class="w-full flex flex-center space-x-2 mt-2">
                            <div class="inline-flex items-center rounded-md bg-gray-50 py-1 px-2 text-xs font-medium text-gray-400 ring-1 ring-inset ring-gray-500/10">ID: <span class="pl-1 text-gray-700">{{ template.id }}</span></div>
                          </div>
                          <div v-if="template.notes" class="template text-[11px] font-light text-gray-500 line-clamp-1" :title="template.notes">{{ template.notes }}</div>
                          <div v-else class="block text-[11px] font-light text-gray-400 line-clamp-1 italic">{{ $t("noNotes") }}</div>
                        </div>
                      </div>
                    </template>
                  </Card>
                </div>
              </div>
            </div>
          </TabPanel>

          <!-- Blocks -->
          <TabPanel>
            <template #header>
              <div class="flex-center-center space-x-2">
                <span> {{ $t("design.blocks") }}</span>
                <Badge :value="(blocks.meta?.total || 0).toString()" class="bg-gray-200 text-gray-500"></Badge>
              </div>
            </template>
            <div v-if="blockClient.busy">
              <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
                <div v-for="block in 8" :key="block.id">
                  <Card class="relative p-card-content-xs">
                    <template #content>
                      <div class="flex relative" :class="[block.deleting ? 'opacity-30 transition-all' : '']">
                        <div class="w-1/4">
                          <Skeleton width="5rem" height="5rem" />
                        </div>
                        <div class="w-3/4 flex flex-col items-start space-y-1">
                          <Skeleton width="100%" height="2rem" />
                          <Skeleton width="100%" height="2rem" />
                          <Skeleton width="100%" height="1rem" />
                        </div>
                      </div>
                    </template>
                  </Card>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3">
                <div>
                  <div class="form-group">
                    <label for="block-filter-by-category" class="form-label-light">{{ $t("templateBuilder.category") }}</label>
                    <Dropdown @change="getBlocks" v-model='blockClient["filter[category][in]"]' :options="blockCategoryOptions" optionLabel="label" optionValue="category" dataKey="category" placeholder="--SELECCIONE--" class="p-inputtext-sm w-full shadow-sm" />
                  </div>
                </div>
              </div>
              <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
                <Card v-if="$verifyPermission(permissions, ['v2.client.template.storeOrUpdate'])" class="relative p-card-content-xs">
                  <template #content>
                    <div class="flex space-x-3 relative">
                      <div class="w-1/4">
                        <Icon icon="gg:if-design" class="w-[90px] h-[100px] border rounded-md text-gray-400" />
                      </div>
                      <div class="w-3/4 flex flex-col justify-center">
                        <div class="space-y-2">
                          <div class="text-base text-gray-700 font-semibold uppercase">{{ $t("design.blankBlock") }}</div>
                          <div class="text-xs text-gray-500 font-semibold">{{ $t("design.StartFromScratch") }}</div>
                          <Button @click="$router.push({ name: 'Block Builder', params: { id: $route.params.id } })" type="button"  :label="$t('design.Startdesigning')" icon="pi pi-file-edit" iconPos="left" class="p-button-primary p-button-sm shadow" />
                        </div>
                      </div>
                    </div>
                  </template>
                </Card>

                <div v-for="block of blocks.data" :key="block.id">
                  <Card class="relative p-card-content-xs">
                    <template #content>
                      <div class="absolute z-100 top-2.5 right-2">
                        <CustomDropdown>
                          <template v-slot:buttons>
                            <a v-if="$verifyPermission(permissions, ['v2.client.template.storeOrUpdate'])" href="javascript:void(0)" @click.stop="$router.push({ name: 'Edit Block', params: { id: $route.params.id, blockId: block.id } })" class="cursor-pointer flex gap-2 items-center w-full px-2 py-2 text-sm transition duration-150 rounded-md text-gray-600 hover:bg-gray-100 hover:text-gray-800 group" role="menuitem">{{ $t("edit") }}</a>
                            <a v-if="$verifyPermission(permissions, ['v2.client.template.duplicate'])" href="javascript:void(0)" @click.stop="duplicateTemplate(block)" class="cursor-pointer flex gap-2 items-center w-full px-2 py-2 text-sm transition duration-150 rounded-md text-gray-600 hover:bg-gray-100 hover:text-gray-800 group" role="menuitem">{{ $t("design.duplicate") }}</a>
                            <a v-if="$verifyPermission(permissions, ['v2.client.template.destroy'])" href="javascript:void(0)" @click.stop="deleteTemplate(block)" class="cursor-pointer flex gap-2 items-center w-full px-2 py-2 text-sm transition duration-150 rounded-md text-gray-600 hover:bg-gray-100 hover:text-gray-800 group" role="menuitem">{{ $t("design.delete") }}</a>
                          </template>
                        </CustomDropdown>
                      </div>
                      <div class="flex space-x-3 relative" :class="[block.deleting ? 'opacity-30 transition-all' : '']">
                        <div class="w-1/4">
                          <img :src="block.thumbnail + '?r=' + Date.now()" class="w-[90px] h-[100px] border rounded-md" />
                        </div>
                        <div class="w-3/4 flex flex-col items-start space-y-1">
                          <div class="block text-sm text-gray-700 font-semibold line-clamp-2" :title="block.name">{{ block.name }}</div>
                          <div class="w-full flex flex-center space-x-2 mt-2">
                            <div class="inline-flex items-center rounded-md bg-gray-50 py-1 px-2 text-xs font-medium text-gray-400 ring-1 ring-inset ring-gray-500/10">ID: <span class="pl-1 text-gray-700">{{ block.id }}</span></div>
                            <div class="inline-flex items-center rounded-md bg-gray-50 py-1 px-2 text-xs font-medium text-gray-400 ring-1 ring-inset ring-gray-500/10">{{ $t("templateBuilder.category") }}: <span class="pl-1 text-gray-700">{{ block.category }}</span></div>
                          </div>
                          <div v-if="block.notes" class="block text-[11px] font-light text-gray-500 line-clamp-1" :title="block.notes">{{ block.notes }}</div>
                          <div v-else class="block text-[11px] font-light text-gray-400 line-clamp-1 italic">{{ $t("noNotes") }}</div>
                        </div>
                      </div>
                    </template>
                  </Card>
                </div>
              </div>
            </div>
          </TabPanel>

          <!-- Appearance -->
          <TabPanel v-if="$verifyPermission(permissions, ['v2.client.project.setAppearance'])">
            <template #header>
              <div class="flex-center-center space-x-2 py-0.5">
                <span>{{ $t("design.aparence") }}</span>
              </div>
            </template>
            <Card>
              <template #content>
                <div v-if="!appearance.formLoaded">
                  <div class="flex flex-col items-center space-y-3 w-full">
                    <Skeleton width="100%" height="15rem" />
                    <Skeleton width="100%" height="15rem" />
                  </div>
                </div>
                <div v-else>
                  <div class="grid grid-cols-1 gap-4 my-4 space-y-3">
                    <Fieldset :legend='$t("design.texts")' :toggleable="true" :collapsed="false">
                      <div class="grid grid-cols-3 gap-4 my-4">
                        <div class="col-span-2 space-y-6">
                          <div class="mb-6">
                            <Message severity="light" icon="pi pi-info-circle" :closable="false">{{ $t("design.messageConfig") }}</Message>
                          </div>
                          <div class="form-group">
                            <div class="flex-center space-x-2">
                              <Icon icon="tabler:palette" class="w-5 h-5" />
                              <div class="font-medium text-sm text-gray-800">{{ $t("design.colors") }}</div>
                            </div>
                            <div class="grid grid-cols-1 md:grid-cols-4 gap-3 mt-3">
                              <div class="form-group">
                                <label for="button-text-color" class="form-label-light">{{ $t("design.textColor") }}</label>
                                <div class="p-inputgroup p-inputgroup-sm relative">
                                  <span class="p-inputgroup-addon">
                                    <span class="block w-4 h-4 rounded border border-gray-200" :style="{ 'background-color': appearance.form.text.styles.color }" @click="appearance.config.text.showPickerTextColor = !appearance.config.text.showPickerTextColor"></span>
                                  </span>
                                  <InputText v-model="appearance.form.text.styles.color" id="button-text-color" name="button-text-color" type="text" class="p-inputtext-sm shadow" />
                                  <ColorPicker v-if="appearance.config.text.showPickerTextColor" theme="light" :color="appearance.form.text.styles.color" @changeColor="changeTextStyleColor" v-click-away="hideTextShowPickerTextColor" class="absolute top-10 color-picker-appearance" />
                                </div>
                              </div>
                              <div class="form-group">
                                <label for="button-background-color" class="form-label-light">{{ $t("design.linkColor") }}</label>
                                <div class="p-inputgroup p-inputgroup-sm relative">
                                  <span class="p-inputgroup-addon">
                                    <span class="block w-4 h-4 rounded border border-gray-200" :style="{ 'background-color': appearance.form.text.styles.linkColor }" @click="appearance.config.text.showPickerLinkColor = !appearance.config.text.showPickerLinkColor"></span>
                                  </span>
                                  <InputText v-model="appearance.form.text.styles.linkColor" id="button-background-color" name="button-background-color" type="text" class="p-inputtext-sm shadow" />
                                  <ColorPicker v-if="appearance.config.text.showPickerLinkColor" theme="light" :color="appearance.form.text.styles.linkColor" @changeColor="changeTextStyleLinkColor" v-click-away="hideTextShowPickerLinkColor" class="absolute top-10 color-picker-appearance" />
                                </div>
                              </div>
                            </div>
                          </div>
                          <Divider />
                          <div class="form-group">
                            <div class="flex-center space-x-2">
                              <Icon icon="tabler:typography" class="w-5 h-5" />
                              <div class="font-medium text-sm text-gray-800">{{ $t("design.typography") }}</div>
                            </div>
                            <div class="grid grid-cols-1 md:grid-cols-4 gap-3 mt-3">
                              <div class="form-group">
                                <label for="connection-name" class="form-label-light">{{ $t("design.font") }}</label>
                                <Dropdown v-model="appearance.form.text.styles.fontFamily" :options="appearance.config.fontFamilyOptions" optionLabel="name" optionValue="code" dataKey="code" class="p-inputtext-sm w-full shadow-sm" />
                              </div>
                              <div class="form-group">
                                <label for="button-font-size" class="form-label-light">{{ $t("design.size") }}</label>
                                <InputNumber v-model="appearance.form.text.styles.fontSize" showButtons :min="1" :step="1" buttonLayout="horizontal" suffix="px" decrementButtonClass="p-button-light p-button-sm" incrementButtonClass="p-button-light p-button-sm" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" mode="decimal" class="p-inputtext-sm w-full text-center" />
                              </div>
                              <div class="form-group">
                                <label for="connection-name" class="form-label-light">{{ $t("design.alignment") }}</label>
                                <Dropdown v-model="appearance.form.text.styles.textAlign" :options="appearance.config.alignOptions" optionLabel="name" optionValue="code" dataKey="code" class="p-inputtext-sm w-full shadow-sm" />
                              </div>
                            </div>
                          </div>
                          <Divider />
                          <div class="form-group">
                            <div class="flex-center space-x-2">
                              <Icon icon="tabler:box-padding" class="w-5 h-5" />
                              <div class="font-medium text-sm text-gray-800">{{ $t("design.padding") }}</div>
                            </div>
                            <div class="grid grid-cols-2 md:grid-cols-4 gap-3 mt-3">
                              <div class="form-group">
                                <label for="button-padding" class="form-label-light">{{ $t("design.top") }}</label>
                                <InputNumber v-model="appearance.form.text.styles.paddingTop" showButtons :min="0" :step="1" buttonLayout="horizontal" suffix="px" decrementButtonClass="p-button-light p-button-sm" incrementButtonClass="p-button-light p-button-sm" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" mode="decimal" class="p-inputtext-sm w-full text-center" />
                              </div>
                              <div class="form-group">
                                <label for="button-padding" class="form-label-light">{{ $t("design.rigth") }}</label>
                                <InputNumber v-model="appearance.form.text.styles.paddingRight" showButtons :min="0" :step="1" buttonLayout="horizontal" suffix="px" decrementButtonClass="p-button-light p-button-sm" incrementButtonClass="p-button-light p-button-sm" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" mode="decimal" class="p-inputtext-sm w-full text-center" />
                              </div>
                              <div class="form-group">
                                <label for="button-padding" class="form-label-light">{{ $t("design.bottom") }}</label>
                                <InputNumber v-model="appearance.form.text.styles.paddingBottom" showButtons :min="0" :step="1" buttonLayout="horizontal" suffix="px" decrementButtonClass="p-button-light p-button-sm" incrementButtonClass="p-button-light p-button-sm" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" mode="decimal" class="p-inputtext-sm w-full text-center" />
                              </div>
                              <div class="form-group">
                                <label for="button-padding" class="form-label-light">{{ $t("design.left") }}</label>
                                <InputNumber v-model="appearance.form.text.styles.paddingLeft" showButtons :min="0" :step="1" buttonLayout="horizontal" suffix="px" decrementButtonClass="p-button-light p-button-sm" incrementButtonClass="p-button-light p-button-sm" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" mode="decimal" class="p-inputtext-sm w-full text-center" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-span-1 bg-blue-50 rounded-md flex flex-col justify-center items-center py-6">
                          <div class="flex-center space-x-2 my-6">
                            <Icon icon="tabler:device-desktop" class="w-6 h-6" />
                            <div class="font-medium text-base text-gray-800">{{ $t("design.desktopView") }}</div>
                          </div>
                          <div class="w-3/4 bg-white p-4 rounded shadow">
                            <div class="space-y-3">
                              <p class="block font-bold" :style="textStyle">Royal blue shirt</p>
                              <p class="block text-sm" :style="textStyle">Fashion can be ordered quickly and without shipping costs in the App Fashion online shop. With us you will find fashion in different colors and from many brands and fashion labels. With us you will definitely find something! <a href="javascript:void(0)" :style="linkStyle">Order now</a> and if you do not like it afterwards, you can return it for free!</p>
                            </div>
                          </div>
                        </div>
                        <div class="col-span-3">
                          <Fieldset :legend='$t("design.mobile")' :toggleable="true" :collapsed="false">
                            <div class="grid grid-cols-3 gap-4 my-4">
                              <div class="col-span-2 space-y-6">
                                <div class="mb-6">
                                  <Message severity="light" icon="pi pi-info-circle" :closable="false">{{ $t("design.messageConfigMobile") }}</Message>
                                </div>
                                <div class="form-group">
                                  <div class="flex-center space-x-2">
                                    <Icon icon="tabler:device-mobile" class="w-5 h-5" />
                                    <div class="font-medium text-sm text-gray-800">{{ $t("design.mobileStyle") }}</div>
                                  </div>
                                  <div class="grid grid-cols-1 md:grid-cols-4 gap-3 mt-3">
                                    <div class="form-group">
                                      <label for="button-font-size" class="form-label-light">{{ $t("design.fontSize") }}</label>
                                      <InputNumber v-model="appearance.form.text.mobileStyles.fontSize" showButtons :min="1" :step="1" buttonLayout="horizontal" suffix="px" decrementButtonClass="p-button-light p-button-sm" incrementButtonClass="p-button-light p-button-sm" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" mode="decimal" class="p-inputtext-sm w-full text-center" />
                                    </div>
                                    <div class="form-group">
                                      <label for="connection-name" class="form-label-light">{{ $t("design.alignment") }}</label>
                                      <Dropdown v-model="appearance.form.text.mobileStyles.textAlign" :options="appearance.config.alignOptions" optionLabel="name" optionValue="code" dataKey="code" class="p-inputtext-sm w-full shadow-sm" />
                                    </div>
                                  </div>
                                </div>
                                <div class="form-group">
                                  <div class="flex-center space-x-2">
                                    <InputSwitch v-model="appearance.form.text.mobileStyles.apply" />
                                    <div class="font-medium text-sm text-gray-800">{{ $t("design.customizePadding") }}</div>
                                  </div>
                                </div>
                                <div v-if="appearance.form.text.mobileStyles.apply" class="form-group">
                                  <div class="flex-center space-x-2">
                                    <Icon icon="tabler:box-padding" class="w-5 h-5" />
                                    <div class="font-medium text-sm text-gray-800">{{ $t("design.padding") }}</div>
                                  </div>
                                  <div class="grid grid-cols-2 md:grid-cols-4 gap-3 mt-3">
                                    <div class="form-group">
                                      <label for="button-padding" class="form-label-light">{{ $t("design.top") }}</label>
                                      <InputNumber v-model="appearance.form.text.mobileStyles.paddingTop" showButtons :min="0" :step="1" buttonLayout="horizontal" suffix="px" decrementButtonClass="p-button-light p-button-sm" incrementButtonClass="p-button-light p-button-sm" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" mode="decimal" class="p-inputtext-sm w-full text-center" />
                                    </div>
                                    <div class="form-group">
                                      <label for="button-padding" class="form-label-light">{{ $t("design.rigth") }}</label>
                                      <InputNumber v-model="appearance.form.text.mobileStyles.paddingRight" showButtons :min="0" :step="1" buttonLayout="horizontal" suffix="px" decrementButtonClass="p-button-light p-button-sm" incrementButtonClass="p-button-light p-button-sm" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" mode="decimal" class="p-inputtext-sm w-full text-center" />
                                    </div>
                                    <div class="form-group">
                                      <label for="button-padding" class="form-label-light">{{ $t("design.bottom") }}</label>
                                      <InputNumber v-model="appearance.form.text.mobileStyles.paddingBottom" showButtons :min="0" :step="1" buttonLayout="horizontal" suffix="px" decrementButtonClass="p-button-light p-button-sm" incrementButtonClass="p-button-light p-button-sm" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" mode="decimal" class="p-inputtext-sm w-full text-center" />
                                    </div>
                                    <div class="form-group">
                                      <label for="button-padding" class="form-label-light">{{ $t("design.left") }}</label>
                                      <InputNumber v-model="appearance.form.text.mobileStyles.paddingLeft" showButtons :min="0" :step="1" buttonLayout="horizontal" suffix="px" decrementButtonClass="p-button-light p-button-sm" incrementButtonClass="p-button-light p-button-sm" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" mode="decimal" class="p-inputtext-sm w-full text-center" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-span-1 bg-blue-50 rounded-md flex flex-col justify-center items-center py-6">
                                <div class="flex-center space-x-2 my-6">
                                  <Icon icon="tabler:device-mobile" class="w-6 h-6" />
                                  <div class="font-medium text-base text-gray-800">{{ $t("design.previewMobile") }}</div>
                                </div>
                                <div class="w-3/4 bg-white p-4 rounded shadow">
                                  <div class="space-y-3">
                                    <p class="block font-bold" :style="textMobileStyle">Royal blue shirt</p>
                                    <p class="block text-sm" :style="textMobileStyle">Fashion can be ordered quickly and without shipping costs in the App Fashion online shop. With us you will find fashion in different colors and from many brands and fashion labels. With us you will definitely find something! <a href="javascript:void(0)" :style="linkStyle">Order now</a> and if you do not like it afterwards, you can return it for free!</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Fieldset>
                        </div>
                      </div>
                    </Fieldset>

                    <Fieldset :legend='$t("design.button")' :toggleable="true" :collapsed="false">
                      <div class="grid grid-cols-3 gap-4 my-4">
                        <div class="col-span-2 space-y-6">
                          <div class="mb-6">
                            <Message severity="light" icon="pi pi-info-circle" :closable="false">{{ $t("design.buttonMessage") }}</Message>
                          </div>
                          <div class="form-group">
                            <div class="flex-center space-x-2">
                              <Icon icon="tabler:palette" class="w-5 h-5" />
                              <div class="font-medium text-sm text-gray-800">{{ $t("design.colors") }}</div>
                            </div>
                            <div class="grid grid-cols-1 md:grid-cols-4 gap-3 mt-3">
                              <div class="form-group">
                                <label for="button-text-color" class="form-label-light">{{ $t("design.textColors") }}</label>
                                <div class="p-inputgroup p-inputgroup-sm relative">
                                  <span class="p-inputgroup-addon">
                                    <span class="block w-4 h-4 rounded border border-gray-200" :style="{ 'background-color': appearance.form.button.styles.color }" @click="appearance.config.button.showPickerTextColor = !appearance.config.button.showPickerTextColor"></span>
                                  </span>
                                  <InputText v-model="appearance.form.button.styles.color" id="button-text-color" name="button-text-color" type="text" class="p-inputtext-sm shadow" />
                                  <ColorPicker v-if="appearance.config.button.showPickerTextColor" theme="light" :color="appearance.form.button.styles.color" @changeColor="changeButtonStyleColor" v-click-away="hideButtonShowPickerTextColor" class="absolute top-10 color-picker-appearance" />
                                </div>
                              </div>
                              <div class="form-group">
                                <label for="button-background-color" class="form-label-light">{{ $t("design.backgroundColor") }}</label>
                                <div class="p-inputgroup p-inputgroup-sm relative">
                                  <span class="p-inputgroup-addon">
                                    <span class="block w-4 h-4 rounded border border-gray-200" :style="{ 'background-color': appearance.form.button.styles.backgroundColor }" @click="appearance.config.button.showPickerBackgroundColor = !appearance.config.button.showPickerBackgroundColor"></span>
                                  </span>
                                  <InputText v-model="appearance.form.button.styles.backgroundColor" id="button-text-color" name="button-text-color" type="text" class="p-inputtext-sm shadow" />
                                  <ColorPicker v-if="appearance.config.button.showPickerBackgroundColor" theme="light" :color="appearance.form.button.styles.backgroundColor" @changeColor="changeButtonStyleBackgroundColor" v-click-away="hideButtonShowPickerBackgroundColor" class="absolute top-10 color-picker-appearance" />
                                </div>
                              </div>
                              <div class="form-group">
                                <label for="button-background-color" class="form-label-light">{{ $t("design.borderColor") }}</label>
                                <div class="p-inputgroup p-inputgroup-sm relative">
                                  <span class="p-inputgroup-addon">
                                    <span class="block w-4 h-4 rounded border border-gray-200" :style="{ 'background-color': appearance.form.button.styles.borderColor }" @click="appearance.config.button.showPickerBorderColor = !appearance.config.button.showPickerBorderColor"></span>
                                  </span>
                                  <InputText v-model="appearance.form.button.styles.borderColor" id="button-text-color" name="button-text-color" type="text" class="p-inputtext-sm shadow" />
                                  <ColorPicker v-if="appearance.config.button.showPickerBorderColor" theme="light" :color="appearance.form.button.styles.borderColor" @changeColor="changeButtonStyleBorderColor" v-click-away="hideButtonShowPickerBorderColor" class="absolute top-10 color-picker-appearance" />
                                </div>
                              </div>
                            </div>
                          </div>
                          <Divider />
                          <div class="form-group">
                            <div class="flex-center space-x-2">
                              <Icon icon="tabler:typography" class="w-5 h-5" />
                              <div class="font-medium text-sm text-gray-800">{{ $t("design.typography") }}</div>
                            </div>
                            <div class="grid grid-cols-1 md:grid-cols-4 gap-3 mt-3">
                              <div class="form-group">
                                <label for="connection-name" class="form-label-light">{{ $t("design.font") }}</label>
                                <Dropdown v-model="appearance.form.button.styles.fontFamily" :options="appearance.config.fontFamilyOptions" optionLabel="name" optionValue="code" dataKey="code" class="p-inputtext-sm w-full shadow-sm" />
                              </div>
                              <div class="form-group">
                                <label for="connection-name" class="form-label-light">{{ $t("design.thickness") }}</label>
                                <Dropdown v-model="appearance.form.button.styles.fontWeight" :options="appearance.config.fontWeightOptions" optionLabel="name" optionValue="code" dataKey="code" class="p-inputtext-sm w-full shadow-sm" />
                              </div>
                              <div class="form-group">
                                <label for="button-font-size" class="form-label-light">{{ $t("design.size") }}</label>
                                <InputNumber v-model="appearance.form.button.styles.fontSize" showButtons :min="1" :step="1" buttonLayout="horizontal" suffix="px" decrementButtonClass="p-button-light p-button-sm" incrementButtonClass="p-button-light p-button-sm" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" mode="decimal" class="p-inputtext-sm w-full text-center" />
                              </div>
                              <div class="form-group">
                                <label for="button-label" class="form-label-light">{{ $t("design.label") }}</label>
                                <InputText v-model="appearance.form.button.label" id="button-label" name="button-label" type="text" class="p-inputtext-sm shadow" />
                              </div>
                            </div>
                          </div>
                          <Divider />
                          <div class="form-group">
                            <div class="flex-center space-x-2">
                              <Icon icon="tabler:border-outer" class="w-5 h-5" />
                              <div class="font-medium text-sm text-gray-800">{{ $t("design.border") }}</div>
                            </div>
                            <div class="grid grid-cols-2 md:grid-cols-4 gap-3 mt-3">
                              <div class="form-group">
                                <label for="button-rounded" class="form-label-light">{{ $t("design.rounded") }}</label>
                                <InputNumber v-model="appearance.form.button.styles.borderRadius" showButtons :min="0" :max="100" :step="1" buttonLayout="horizontal" suffix="px" decrementButtonClass="p-button-light p-button-sm" incrementButtonClass="p-button-light p-button-sm" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" mode="decimal" class="p-inputtext-sm w-full text-center" />
                              </div>
                              <div class="form-group">
                                <label for="button-rounded" class="form-label-light">{{ $t("design.thickness") }}</label>
                                <InputNumber v-model="appearance.form.button.styles.border" showButtons :min="0" :max="100" :step="1" buttonLayout="horizontal" suffix="px" decrementButtonClass="p-button-light p-button-sm" incrementButtonClass="p-button-light p-button-sm" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" mode="decimal" class="p-inputtext-sm w-full text-center" />
                              </div>
                              <div class="form-group">
                                <label for="connection-name" class="form-label-light">{{ $t("design.style") }}</label>
                                <Dropdown v-model="appearance.form.button.styles.borderStyle" :options="appearance.config.borderStyleOptions" optionLabel="name" optionValue="code" dataKey="code" class="p-inputtext-sm w-full shadow-sm" />
                              </div>
                            </div>
                          </div>
                          <Divider />
                          <div class="form-group">
                            <div class="flex-center space-x-2">
                              <Icon icon="tabler:box-padding" class="w-5 h-5" />
                              <div class="font-medium text-sm text-gray-800">{{ $t("design.padding") }}</div>
                            </div>
                            <div class="grid grid-cols-2 md:grid-cols-4 gap-3 mt-3">
                              <div class="form-group">
                                <label for="button-padding" class="form-label-light">{{ $t("design.top") }}</label>
                                <InputNumber v-model="appearance.form.button.styles.paddingTop" showButtons :min="0" :step="1" buttonLayout="horizontal" suffix="px" decrementButtonClass="p-button-light p-button-sm" incrementButtonClass="p-button-light p-button-sm" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" mode="decimal" class="p-inputtext-sm w-full text-center" />
                              </div>
                              <div class="form-group">
                                <label for="button-padding" class="form-label-light">{{ $t("design.rigth") }}</label>
                                <InputNumber v-model="appearance.form.button.styles.paddingRight" showButtons :min="0" :step="1" buttonLayout="horizontal" suffix="px" decrementButtonClass="p-button-light p-button-sm" incrementButtonClass="p-button-light p-button-sm" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" mode="decimal" class="p-inputtext-sm w-full text-center" />
                              </div>
                              <div class="form-group">
                                <label for="button-padding" class="form-label-light">{{ $t("design.bottom") }}</label>
                                <InputNumber v-model="appearance.form.button.styles.paddingBottom" showButtons :min="0" :step="1" buttonLayout="horizontal" suffix="px" decrementButtonClass="p-button-light p-button-sm" incrementButtonClass="p-button-light p-button-sm" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" mode="decimal" class="p-inputtext-sm w-full text-center" />
                              </div>
                              <div class="form-group">
                                <label for="button-padding" class="form-label-light">{{ $t("design.left") }}</label>
                                <InputNumber v-model="appearance.form.button.styles.paddingLeft" showButtons :min="0" :step="1" buttonLayout="horizontal" suffix="px" decrementButtonClass="p-button-light p-button-sm" incrementButtonClass="p-button-light p-button-sm" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" mode="decimal" class="p-inputtext-sm w-full text-center" />
                              </div>
                            </div>
                          </div>
                          <Divider />
                          <div class="form-group">
                            <div class="flex-center space-x-2">
                              <Icon icon="tabler:box-margin" class="w-5 h-5" />
                              <div class="font-medium text-sm text-gray-800">{{ $t("design.margin") }}</div>
                            </div>
                            <div class="grid grid-cols-2 md:grid-cols-4 gap-3 mt-3">
                              <div class="form-group">
                                <label for="button-margin" class="form-label-light">{{ $t("design.top") }}</label>
                                <InputNumber v-model="appearance.form.button.styles.marginTop" showButtons :min="0" :step="1" buttonLayout="horizontal" suffix="px" decrementButtonClass="p-button-light p-button-sm" incrementButtonClass="p-button-light p-button-sm" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" mode="decimal" class="p-inputtext-sm w-full text-center" />
                              </div>
                              <div class="form-group">
                                <label for="button-margin" class="form-label-light">{{ $t("design.rigth") }}</label>
                                <InputNumber v-model="appearance.form.button.styles.marginRight" showButtons :min="0" :step="1" buttonLayout="horizontal" suffix="px" decrementButtonClass="p-button-light p-button-sm" incrementButtonClass="p-button-light p-button-sm" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" mode="decimal" class="p-inputtext-sm w-full text-center" />
                              </div>
                              <div class="form-group">
                                <label for="button-margin" class="form-label-light">{{ $t("design.bottom") }}</label>
                                <InputNumber v-model="appearance.form.button.styles.marginBottom" showButtons :min="0" :step="1" buttonLayout="horizontal" suffix="px" decrementButtonClass="p-button-light p-button-sm" incrementButtonClass="p-button-light p-button-sm" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" mode="decimal" class="p-inputtext-sm w-full text-center" />
                              </div>
                              <div class="form-group">
                                <label for="button-margin" class="form-label-light">{{ $t("design.left") }}</label>
                                <InputNumber v-model="appearance.form.button.styles.marginLeft" showButtons :min="0" :step="1" buttonLayout="horizontal" suffix="px" decrementButtonClass="p-button-light p-button-sm" incrementButtonClass="p-button-light p-button-sm" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" mode="decimal" class="p-inputtext-sm w-full text-center" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-span-1 bg-blue-50 rounded-md flex flex-col justify-center items-center py-6">
                          <div class="flex-center space-x-2 my-6">
                            <Icon icon="tabler:device-desktop" class="w-6 h-6" />
                            <div class="font-medium text-base text-gray-800">{{ $t("design.desktopView") }}</div>
                          </div>
                          <div class="w-3/4 bg-white p-4 rounded shadow">
                            <div class="space-y-3">
                              <div>
                                <div class="w-full text-center">
                                  <a href="javascript:void(0)" class="inline-block" :style="buttonStyle">
                                    {{ appearance.form.button.label }}
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-span-3">
                          <Fieldset :legend='$t("design.mobile")' :toggleable="true" :collapsed="false">
                            <div class="grid grid-cols-3 gap-4 my-4">
                              <div class="col-span-2 space-y-6">
                                <div class="mb-6">
                                  <Message severity="light" icon="pi pi-info-circle" :closable="false">{{ $t("design.mobileStyle") }}</Message>
                                </div>
                                <div class="form-group">
                                  <div class="flex-center space-x-2">
                                    <Icon icon="tabler:device-mobile" class="w-5 h-5" />
                                    <div class="font-medium text-sm text-gray-800">{{ $t("design.buttonMessageMobile") }}</div>
                                  </div>
                                  <div class="grid grid-cols-1 md:grid-cols-4 gap-3 mt-3">
                                    <div class="form-group">
                                      <label for="button-font-size" class="form-label-light">{{ $t("design.fontSize") }}</label>
                                      <InputNumber v-model="appearance.form.button.mobileStyles.fontSize" showButtons :min="1" :step="1" buttonLayout="horizontal" suffix="px" decrementButtonClass="p-button-light p-button-sm" incrementButtonClass="p-button-light p-button-sm" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" mode="decimal" class="p-inputtext-sm w-full text-center" />
                                    </div>
                                  </div>
                                </div>
                                <div class="form-group">
                                  <div class="flex-center space-x-2">
                                    <InputSwitch v-model="appearance.form.button.mobileStyles.apply" />
                                    <div class="font-medium text-sm text-gray-800">{{ $t("design.customizePadding") }}</div>
                                  </div>
                                </div>
                                <div v-if="appearance.form.button.mobileStyles.apply" class="form-group">
                                  <div class="flex-center space-x-2">
                                    <Icon icon="tabler:box-padding" class="w-5 h-5" />
                                    <div class="font-medium text-sm text-gray-800">{{ $t("design.padding") }}</div>
                                  </div>
                                  <div class="grid grid-cols-2 md:grid-cols-4 gap-3 mt-3">
                                    <div class="form-group">
                                      <label for="button-padding" class="form-label-light">{{ $t("design.top") }}</label>
                                      <InputNumber v-model="appearance.form.button.mobileStyles.paddingTop" showButtons :min="0" :step="1" buttonLayout="horizontal" suffix="px" decrementButtonClass="p-button-light p-button-sm" incrementButtonClass="p-button-light p-button-sm" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" mode="decimal" class="p-inputtext-sm w-full text-center" />
                                    </div>
                                    <div class="form-group">
                                      <label for="button-padding" class="form-label-light">{{ $t("design.rigth") }}</label>
                                      <InputNumber v-model="appearance.form.button.mobileStyles.paddingRight" showButtons :min="0" :step="1" buttonLayout="horizontal" suffix="px" decrementButtonClass="p-button-light p-button-sm" incrementButtonClass="p-button-light p-button-sm" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" mode="decimal" class="p-inputtext-sm w-full text-center" />
                                    </div>
                                    <div class="form-group">
                                      <label for="button-padding" class="form-label-light">{{ $t("design.bottom") }}</label>
                                      <InputNumber v-model="appearance.form.button.mobileStyles.paddingBottom" showButtons :min="0" :step="1" buttonLayout="horizontal" suffix="px" decrementButtonClass="p-button-light p-button-sm" incrementButtonClass="p-button-light p-button-sm" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" mode="decimal" class="p-inputtext-sm w-full text-center" />
                                    </div>
                                    <div class="form-group">
                                      <label for="button-padding" class="form-label-light">{{ $t("design.left") }}</label>
                                      <InputNumber v-model="appearance.form.button.mobileStyles.paddingLeft" showButtons :min="0" :step="1" buttonLayout="horizontal" suffix="px" decrementButtonClass="p-button-light p-button-sm" incrementButtonClass="p-button-light p-button-sm" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" mode="decimal" class="p-inputtext-sm w-full text-center" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-span-1 bg-blue-50 rounded-md flex flex-col justify-center items-center py-6">
                                <div class="flex-center space-x-2 my-6">
                                  <Icon icon="tabler:device-mobile" class="w-6 h-6" />
                                  <div class="font-medium text-base text-gray-800">{{ $t("design.previewMobile") }}</div>
                                </div>
                                <div class="w-3/4 bg-white p-4 rounded shadow">
                                  <div class="space-y-3">
                                    <div>
                                      <div class="w-full text-center">
                                        <a href="javascript:void(0)" class="inline-block" :style="buttonMobileStyle">
                                          {{ appearance.form.button.label }}
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Fieldset>
                        </div>
                      </div>
                    </Fieldset>

                    <Fieldset :legend='$t("design.currency.title")' :toggleable="true" :collapsed="false">
                      <div class="grid grid-cols-1 gap-4 my-4">
                        <div class="space-y-6">
                          <div class="mb-6">
                            <Message severity="light" icon="pi pi-info-circle" :closable="false">{{ $t("design.currency.message") }}</Message>
                          </div>
                          <div class="form-group">
                            <div class="flex-center space-x-2 my-3">
                              <Icon icon="tabler:currency-dollar" class="w-6 h-6" />
                              <div class="font-medium text-base text-gray-800">{{ $t("design.currency.title") }}</div>
                            </div>
                            <div class="grid grid-cols-1 md:grid-cols-4 gap-3">
                              <div class="form-group col-span-4">
                                <label for="priceFields" class="form-label-light">{{ $t("design.currency.fields") }}</label>
                                <InputText v-model="appearance.form.currency.fields" id="priceFields" name="priceFields" type="text" class="p-inputtext-sm shadow" />
                              </div>
                              <div class="form-group">
                                <label for="priceSymbol" class="form-label-light">{{ $t("design.currency.symbol") }}</label>
                                <InputText v-model="appearance.form.currency.symbol" id="priceSymbol" name="priceSymbol" type="text" class="p-inputtext-sm shadow" />
                              </div>
                              <div class="form-group">
                                <label for="priceDecimalSeparator" class="form-label-light">{{ $t("design.currency.decimalSeparator") }}</label>
                                <InputText v-model="appearance.form.currency.decimal_separator" id="priceDecimalSeparator" name="priceDecimalSeparator" type="text" class="p-inputtext-sm shadow" />
                              </div>
                              <div class="form-group">
                                <label for="priceThousandsSeparator" class="form-label-light">{{ $t("design.currency.thousandsSeparator") }}</label>
                                <InputText v-model="appearance.form.currency.thousands_separator" id="priceThousandsSeparator" name="priceThousandsSeparator" type="text" class="p-inputtext-sm shadow" />
                              </div>
                              <div class="form-group">
                                <label for="priceDecimalPlaces" class="form-label-light">{{ $t("design.currency.decimalPlaces") }}</label>
                                <InputNumber v-model="appearance.form.currency.decimal_places" showButtons id="priceDecimalPlaces" name="priceDecimalPlaces" :min="0" :max="3" :step="1" buttonLayout="horizontal" decrementButtonClass="p-button-light p-button-sm" incrementButtonClass="p-button-light p-button-sm" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" mode="decimal" class="p-inputtext-sm w-full text-center" />
                              </div>
                            </div>
                          </div>
                          <Divider />
                        </div>
                      </div>
                    </Fieldset>
                  </div>
                  <div class="flex justify-end">
                    <Button @click="submitAppearance" :loading="setAppearanceClient.busy" type="button" :label='$t("design.save")' iconPos="right" class="p-button-primary shadow" />
                  </div>
                </div>
              </template>
            </Card>
          </TabPanel>
        </TabView>
      </div>
    </div>

    <Dialog :header="$t('design.export')" v-model:visible="exportToForm.show" position="center" modal :dismissableMask="true" :style="{ width: '30rem', padding: 0 }">
      <div class="flex flex-col">
        <form @submit.prevent="syncTemplate">
          <div class="form-group">
            <label for="credential" class="form-label">{{ $t("Credencial") }} <LabelRequired /></label>
            <Dropdown v-model="exportToForm.form.project_credential_id" :options="exportToForm.projectCredentials" optionLabel="name" optionValue="id" dataKey="id" placeholder="--SELECCIONE--" class="p-inputtext-sm w-full shadow-sm" />
          </div>
          <div class="flex items-center justify-end space-x-3 pt-3">
            <Button @click="exportToForm.show = false" type="button" :label="$t('cancel')" class="p-button-light shadow" />
            <Button type="submit" :label="$t('design.export')" :loading="exportToForm.form.busy" class="p-button-primary shadow" />
          </div>
        </form>
      </div>
    </Dialog>
  </div>
</template>

<script>
import { Icon } from "@iconify/vue"
import Dialog from "primevue/dialog"
import TabView from "primevue/tabview"
import TabPanel from "primevue/tabpanel"
import Card from "primevue/card"
import Button from "primevue/button"
import Badge from "primevue/badge"
import Skeleton from "primevue/skeleton"
import Fieldset from "primevue/fieldset"
import Dropdown from "primevue/dropdown"
import InputText from "primevue/inputtext"
import InputNumber from "primevue/inputnumber"
import InputSwitch from "primevue/inputswitch"
import Message from "primevue/message"
import Divider from "primevue/divider"
import { ColorPicker } from "vue-color-kit"
import { saveAs } from "file-saver"
import CustomDropdown from "@/components/Dropdowns/DropdownDots"
import LabelRequired from "@/components/LabelRequired"
import ApiRoute from "@/config/ApiRoute"
import WidgetEditorConfig from "@/config/WidgetEditorConfig"
import FormClient from "@/config/FormClient"
import { mapGetters } from 'vuex'

const sprintf = require("sprintf-js").sprintf

export default {
  props: {
    id: String
  },

  components: {
    Icon,
    Dialog,
    TabView,
    TabPanel,
    Card,
    Button,
    Badge,
    Skeleton,
    Fieldset,
    Dropdown,
    InputText,
    InputNumber,
    InputSwitch,
    Message,
    Divider,
    ColorPicker,
    CustomDropdown,
    LabelRequired
  },

  data: function () {
    return {
      appearance: {
        config: {
          text: {
            showPickerTextColor: false,
            showPickerLinkColor: false
          },
          button: {
            showPickerTextColor: false,
            showPickerBackgroundColor: false,
            showPickerBorderColor: false
          },
          fontFamilyOptions: [
            {
              name: "Arial",
              code: "Arial, 'Helvetica Neue', Helvetica, sans-serif"
            },
            {
              name: "Courier",
              code: "'Courier New', Courier, 'Lucida Sans Typewriter', 'Lucida Typewriter', monospace"
            },
            {
              name: "Georgia",
              code: "Georgia, Times, 'Times New Roman', serif"
            },
            {
              name: "Helvetica",
              code: "'Helvetica Neue', Helvetica, Arial, sans-serif"
            },
            {
              name: "Lucida Sans",
              code: "'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Geneva, Verdana, sans-serif"
            },
            {
              name: "Tahoma",
              code: "Tahoma, Verdana, Segoe, sans-serif"
            },
            {
              name: "Times New Roman",
              code: "TimesNewRoman, 'Times New Roman', Times, Beskerville, Georgia, serif"
            },
            {
              name: "Trebuchet MS",
              code: "'Trebuchet MS', 'Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans', Tahoma, sans-serif"
            },
            {
              name: "Verdana",
              code: "Verdana, Geneva, sans-serif"
            },
            {
              name: "ヒラギノ角ゴ Pro W3",
              code: "ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro,Osaka, メイリオ, Meiryo, ＭＳ Ｐゴシック, MS PGothic, sans-serif"
            },
            {
              name: "メイリオ",
              code: "メイリオ, Meiryo, ＭＳ Ｐゴシック, MS PGothic, ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro,Osaka, sans-serif"
            },
            {
              name: "Bitter",
              code: "'Bitter', Georgia, Times, 'Times New Roman', serif",
              url: "https://fonts.googleapis.com/css?family=Bitter"
            },
            {
              name: "Droid Serif",
              code: "'Droid Serif', Georgia, Times, 'Times New Roman', serif",
              url: "https://fonts.googleapis.com/css?family=Droid+Serif"
            },
            {
              name: "Lato",
              code: "'Lato', Tahoma, Verdana, Segoe, sans-serif",
              url: "https://fonts.googleapis.com/css?family=Lato"
            },
            {
              name: "Open Sans",
              code: "'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
              url: "https://fonts.googleapis.com/css?family=Open+Sans"
            },
            {
              name: "Roboto",
              code: "'Roboto', Tahoma, Verdana, Segoe, sans-serif",
              url: "https://fonts.googleapis.com/css?family=Roboto"
            },
            {
              name: "Source Sans Pro",
              code: "'Source Sans Pro', Tahoma, Verdana, Segoe, sans-serif",
              url: "https://fonts.googleapis.com/css?family=Source+Sans+Pro"
            },
            {
              name: "Montserrat",
              code: "'Montserrat', 'Trebuchet MS', 'Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans', Tahoma, sans-serif",
              url: "https://fonts.googleapis.com/css?family=Montserrat"
            },
            {
              name: "Ubuntu",
              code: "'Ubuntu', Tahoma, Verdana, Segoe, sans-serif",
              url: "https://fonts.googleapis.com/css?family=Ubuntu"
            }
          ],
          borderStyleOptions: [
            {
              code: "solid",
              name: "Solido"
            },
            {
              code: "dotted",
              name: "Punteado"
            },
            {
              code: "dashed",
              name: "Lineado"
            }
          ],
          fontWeightOptions: [
            {
              code: "light",
              name: "Delgado"
            },
            {
              code: "normal",
              name: "Normal"
            },
            {
              code: "bold",
              name: "Negrita"
            }
          ],
          alignOptions: [
            {
              code: "left",
              name: "Izquierda"
            },
            {
              code: "center",
              name: "Centrado"
            },
            {
              code: "right",
              name: "Derecha"
            }
          ]
        },
        form: {},
        formLoaded: false
      },
      getAppearanceClient: new FormClient(),
      setAppearanceClient: new FormClient({ appearance: {} }),
      templateClient: new FormClient({ "filter[type][in]": "email" }),
      templates: {},
      blockClient: new FormClient({
        "filter[type][in]": "emailSaveRow",
        "filter[category][in]": null
      }),
      blocks: {},
      blockCategoryClient: new FormClient({type: "emailSaveRow"}),
      blockCategoryOptions: [],
      exportToForm: {
        show: false,
        template: {},
        form: new FormClient({ project_credential_id: null }),
        projectCredentials: [],
        projectCredentialsClient: new FormClient()
      }
    }
  },

  computed: {
    ...mapGetters({permissions: 'Projects/permissionsForProject'}),
    textStyle() {
      return {
        "font-family": this.appearance.form.text.styles.fontFamily,
        "font-size": `${this.appearance.form.text.styles.fontSize}px`,
        color: this.appearance.form.text.styles.color,
        "text-align": this.appearance.form.text.styles.textAlign,
        "padding-top": `${this.appearance.form.text.styles.paddingTop}px`,
        "padding-right": `${this.appearance.form.text.styles.paddingRight}px`,
        "padding-bottom": `${this.appearance.form.text.styles.paddingBottom}px`,
        "padding-left": `${this.appearance.form.text.styles.paddingLeft}px`
      }
    },

    textMobileStyle() {
      return {
        "font-family": this.appearance.form.text.styles.fontFamily,
        "font-size": `${this.appearance.form.text.mobileStyles.fontSize}px`,
        color: this.appearance.form.text.styles.color,
        "text-align": this.appearance.form.text.mobileStyles.textAlign,
        "padding-top": this.appearance.form.text.mobileStyles.apply ? `${this.appearance.form.text.mobileStyles.paddingTop}px` : `${this.appearance.form.text.styles.paddingTop}px`,
        "padding-right": this.appearance.form.text.mobileStyles.apply ? `${this.appearance.form.text.mobileStyles.paddingRight}px` : `${this.appearance.form.text.styles.paddingRight}px`,
        "padding-bottom": this.appearance.form.text.mobileStyles.apply ? `${this.appearance.form.text.mobileStyles.paddingBottom}px` : `${this.appearance.form.text.styles.paddingBottom}px`,
        "padding-left": this.appearance.form.text.mobileStyles.apply ? `${this.appearance.form.text.mobileStyles.paddingLeft}px` : `${this.appearance.form.text.styles.paddingLeft}px`
      }
    },

    linkStyle() {
      return {
        "font-family": this.appearance.form.text.styles.fontFamily,
        color: this.appearance.form.text.styles.linkColor
      }
    },

    buttonStyle() {
      return {
        color: this.appearance.form.button.styles.color,
        "font-family": this.appearance.form.button.styles.fontFamily,
        "font-weight": this.appearance.form.button.styles.fontWeight,
        "font-size": `${this.appearance.form.button.styles.fontSize}px`,
        "background-color": this.appearance.form.button.styles.backgroundColor,
        border: `${this.appearance.form.button.styles.border}px`,
        "border-color": this.appearance.form.button.styles.borderColor,
        "border-radius": `${this.appearance.form.button.styles.borderRadius}px`,
        "border-style": this.appearance.form.button.styles.borderStyle,
        "padding-top": `${this.appearance.form.button.styles.paddingTop}px`,
        "padding-right": `${this.appearance.form.button.styles.paddingRight}px`,
        "padding-bottom": `${this.appearance.form.button.styles.paddingBottom}px`,
        "padding-left": `${this.appearance.form.button.styles.paddingLeft}px`,
        "margin-top": `${this.appearance.form.button.styles.marginTop}px`,
        "margin-right": `${this.appearance.form.button.styles.marginRight}px`,
        "margin-bottom": `${this.appearance.form.button.styles.marginBottom}px`,
        "margin-left": `${this.appearance.form.button.styles.marginLeft}px`
      }
    },

    buttonMobileStyle() {
      return {
        color: this.appearance.form.button.styles.color,
        "font-family": this.appearance.form.button.styles.fontFamily,
        "font-weight": this.appearance.form.button.styles.fontWeight,
        "font-size": `${this.appearance.form.button.mobileStyles.fontSize}px`,
        "background-color": this.appearance.form.button.styles.backgroundColor,
        border: `${this.appearance.form.button.styles.border}px`,
        "border-color": this.appearance.form.button.styles.borderColor,
        "border-radius": `${this.appearance.form.button.styles.borderRadius}px`,
        "border-style": this.appearance.form.button.styles.borderStyle,
        "padding-top": this.appearance.form.button.mobileStyles.apply ? `${this.appearance.form.button.mobileStyles.paddingTop}px` : `${this.appearance.form.button.styles.paddingTop}px`,
        "padding-right": this.appearance.form.button.mobileStyles.apply ? `${this.appearance.form.button.mobileStyles.paddingRight}px` : `${this.appearance.form.button.styles.paddingRight}px`,
        "padding-bottom": this.appearance.form.button.mobileStyles.apply ? `${this.appearance.form.button.mobileStyles.paddingBottom}px` : `${this.appearance.form.button.styles.paddingBottom}px`,
        "padding-left": this.appearance.form.button.mobileStyles.apply ? `${this.appearance.form.button.mobileStyles.paddingLeft}px` : `${this.appearance.form.button.styles.paddingLeft}px`,
        "margin-top": `${this.appearance.form.button.styles.marginTop}px`,
        "margin-right": `${this.appearance.form.button.styles.marginRight}px`,
        "margin-bottom": `${this.appearance.form.button.styles.marginBottom}px`,
        "margin-left": `${this.appearance.form.button.styles.marginLeft}px`
      }
    }
  },

  async mounted() {
    this.getTemplates()
    this.getBlocks()
    this.getCategoryBlocksGrouped()
    this.getAppearance()
  },

  methods: {
    async getTemplates() {
      const response = await this.templateClient.get(sprintf(ApiRoute.template.index, { project_id: this.id }))
      this.templates = response.data
    },

    async getBlocks() {
      const response = await this.blockClient.get(sprintf(ApiRoute.template.index, { project_id: this.id }))
      this.blocks = response.data
    },

    async getCategoryBlocksGrouped() {
      const response = await this.blockCategoryClient.get(sprintf(ApiRoute.template.groupedCategories, { project_id: this.id }))

      let options = [
        {
          label: "--SELECCIONE--",
          category: null
        }
      ]

      let optionsFiltered = response.data.data.map(d => {
        return {
          label: `${d.category}: ${d.total}`,
          category: d.category
        }
      })

      this.blockCategoryOptions = options.concat(optionsFiltered)
    },

    async openSyncModal(template) {
      this.exportToForm.show = true
      this.exportToForm.template = template

      const response = await this.exportToForm.projectCredentialsClient.get(sprintf(ApiRoute.project.credentials, { project_id: this.id }))

      this.exportToForm.projectCredentials = response.data.data.filter((project_credential) => [2, 19].indexOf(project_credential.channel_id) > -1)
    },

    async syncTemplate() {
      try {
        await this.exportToForm.form.post(sprintf(ApiRoute.template.sync, { template_id: this.exportToForm.template.id }))
        this.exportToForm.show = false
        this.exportToForm.template = {}
        this.exportToForm.form.project_credential_id = this.exportToForm.projectCredentials = []
        this.$toast.success(this.$t("Template sincronizado con éxito"))
      } catch (error) {
        this.$toast.error(this.$t("Hubo un problema en la sincronización"))
      }
    },

    async downloadTemplate(template) {
      let filename = template.name + "_" + new Date().getTime()
      saveAs(new Blob([template.message]), `${filename}.html`)
    },

    async duplicateTemplate(template) {
      await new FormClient().post(sprintf(ApiRoute.template.duplicate, { template_id: template.id }))
      this.getTemplates()
      this.getBlocks()
    },

    async deleteTemplate(template) {
      this.$confirm.require({
        group: "confirm",
        message: this.$t("You won't be able to revert this!"),
        header: this.$t("Are you sure?"),
        icon: "pi pi-info-circle",
        acceptClass: "p-button-primary",
        acceptLabel: this.$t("yesContinue"),
        rejectLabel: this.$t("cancel"),
        accept: async () => {
          template.deleting = true
          await new FormClient().delete(sprintf(ApiRoute.template.delete, { template_id: template.id }))
          this.getTemplates()
          this.getBlocks()
        }
      })
    },

    async getAppearance() {
      const response = await this.getAppearanceClient.get(sprintf(ApiRoute.project.appearance, { project_id: this.id }))
      const appearance = response.data

      this.appearance.form = Object.keys(appearance).length ? { ...WidgetEditorConfig.form, ...appearance } : { ...WidgetEditorConfig.form }
      this.appearance.formLoaded = true
    },

    async submitAppearance() {
      this.setAppearanceClient.appearance = { ...this.appearance.form }
      await this.setAppearanceClient.post(sprintf(ApiRoute.project.appearance, { project_id: this.id }))

      this.$toast.success(this.$t("Apariencia guardada con éxito"))
    },

    // Text color picker

    hideTextShowPickerTextColor() {
      this.appearance.config.text.showPickerTextColor = false
    },

    hideTextShowPickerLinkColor() {
      this.appearance.config.text.showPickerLinkColor = false
    },

    changeTextStyleColor(color) {
      this.appearance.form.text.styles.color = color.hex
    },

    changeTextStyleLinkColor(color) {
      this.appearance.form.text.styles.linkColor = color.hex
    },

    // Button color picker

    hideButtonShowPickerTextColor() {
      this.appearance.config.button.showPickerTextColor = false
    },

    changeButtonStyleColor(color) {
      this.appearance.form.button.styles.color = color.hex
    },

    hideButtonShowPickerBackgroundColor() {
      this.appearance.config.button.showPickerBackgroundColor = false
    },

    changeButtonStyleBackgroundColor(color) {
      this.appearance.form.button.styles.backgroundColor = color.hex
    },

    hideButtonShowPickerBorderColor() {
      this.appearance.config.button.showPickerBorderColor = false
    },

    changeButtonStyleBorderColor(color) {
      this.appearance.form.button.styles.borderColor = color.hex
    }
  }
}
</script>
