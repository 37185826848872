export default {
  form: {
    text: {
      styles: {
        fontFamily: "Arial, 'Helvetica Neue', Helvetica, sans-serif",
        color: "#000000",
        linkColor: "#0068a5",
        textAlign: "left",
        fontSize: 14,
        paddingTop: 10,
        paddingRight: 0,
        paddingBottom: 10,
        paddingLeft: 0
      },
      mobileStyles: {
        apply: false,
        textAlign: "left",
        fontSize: 14,
        paddingTop: 5,
        paddingRight: 0,
        paddingBottom: 5,
        paddingLeft: 0
      }
    },
    button: {
      label: "Button",
      styles: {
        fontFamily: "Arial, 'Helvetica Neue', Helvetica, sans-serif",
        fontWeight: "normal",
        fontSize: 14,
        color: "#ffffff",
        backgroundColor: "#3AAEE0",
        border: 1,
        borderColor: "transparent",
        borderStyle: "solid",
        borderRadius: 10,
        paddingBottom: 10,
        paddingLeft: 25,
        paddingRight: 25,
        paddingTop: 10,
        marginBottom: 5,
        marginLeft: 0,
        marginRight: 0,
        marginTop: 5
      },
      mobileStyles: {
        apply: false,
        fontSize: 14,
        paddingBottom: 10,
        paddingLeft: 25,
        paddingRight: 25,
        paddingTop: 10
      }
    },
    currency: {
      fields: null,
      symbol: null,
      decimal_places: null,
      decimal_separator: null,
      thousands_separator: null
    }
  }
}
